<template>
  <div class="hover:tw-border-red tw-group tw-border-gray-n0 tw-border tw-shadow-md tw-p-6 tw-flex tw-items-center tw-rounded-xl">
    <div class="group-hover:tw-bg-red-100 group-hover:tw-text-red tw-bg-gray-n0 tw-text-gray-n3 tw-h-14 tw-w-14 tw-flex tw-items-center tw-justify-center tw-mr-4 tw-rounded-xl">
      <slot></slot>
    </div>
    <div class="tw-w-full">
      <div class="tw-flex">
        <span class="tw-text-black group-hover:tw-text-red tw-block tw-font-semibold tw-leading-relaxed tw-mb-0.5">
          {{ title }}
        </span>
        <span v-if="showCheckbox" class="tw-ml-auto">
          <check-square-icon v-if="completeness" class="tw-w-5 tw-h-5 tw-ml-2 tw-text-green-check"
          stroke-width="2" />
          <square-icon v-if="!completeness" class="tw-w-5 tw-h-5 tw-ml-2"
          stroke-width="2" />

        </span>
      </div>
      <div class="tw-flex">
        <span class="tw-text-sm tw-text-gray-n3">{{ description }}</span>
      </div>

    </div>
  </div>

</template>

<script>
import { CheckSquareIcon, SquareIcon   } from "vue-feather-icons";

export default {
  props: [
      'title',
      'description',
      'showCheckbox',
      'completeness'
  ],
  components:{
    CheckSquareIcon, SquareIcon
  }
}

</script>