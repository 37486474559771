<template>
  <div>
    <div class="tw-bg-white tw-p-4" v-if="!submitted">
      <form class="tw-flex tw-flex-col tw-gap-5 tw-pr-2 tw-pl-2" @submit.prevent="onSubmit">
        <div class="font-weight-bold tw-flex tw-pb-5 tw-pt-3 ">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 20H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <h2 class="tw-pl-3">{{$t('vendor.review_invite')}}</h2>
        </div>
        <div class="tw-flex tw-flex-col">
          <h2 class="tw-pb-2 tw-text-gray-n3">{{$t('general.email')}}</h2>
          <input type="email" class="tw-ring-1 tw-ring-gray-n1 tw-rounded" v-model="email"
                 placeholder="example@mail.com">
                 <ml-error-msg :msg="errors['email']" />
        </div>
        <div class="tw-flex tw-flex-col">
          <h2 class="tw-pb-2 tw-text-gray-n3">{{$t('general.name')}}</h2>
          <input type="text" class="tw-ring-1 tw-ring-gray-n1 tw-rounded" v-model="name" placeholder="Jane Doe">
          <ml-error-msg :msg="errors['name']" />
        </div>

        <div class="tw-flex tw-flex-col">
          <h2 class="tw-pb-2 tw-text-gray-n3">{{$t('general.message')}}</h2>
          <span @click="body = selectedTemplate" 
          class="tw-cursor-pointer tw-text-brown tw-text-xs tw-inline-flex tw-items-center tw-justify-end">Load template</span>
          <editor
              id="body" name="body"
              v-model="body"
              :init="{
                    
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help'
              }"
          />                  
          <ml-error-msg :msg="errors['body']" />
        </div>

        <div class="tw-flex tw-justify-evenly tw-w-full tw-gap-2 tw-pt-2">
          <Button button-type="button" type="s_dark" class="tw-w-3/6 tw-text-center" @click="close">{{ $t('rating.cancel') }}</Button>
          <Button type="p_dark" class="tw-w-3/6">{{ $t('rating.send') }}</Button>
        </div>
      </form>
    </div>
    <div v-if="submitted">
      <div class="overlay">
        <form class="tw-flex tw-flex-col tw-gap-5 tw-pr-2 tw-pl-2">

          <div class="font-weight-bold tw-flex tw-pb-5 tw-pt-3 ">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 20H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                  stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <div class="tw-flex tw-flex-col">
              <h2 class="tw-pb-2 tw-text-gray-n3">Successfully sent to {{ name }}!</h2>
            </div>
            <div class="tw-flex tw-justify-evenly tw-w-full tw-gap-2 tw-pt-2">
              <Button type="s_dark" class="tw-w-3/6 tw-text-center">Close</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


</template>

<script>
import Button from "./Button";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
  name: "inviteRate",
  components: {Button,MlErrorMsg,Editor},
  data() {
    return {
      email: '',
      name: '',
      body: '',
      submitted: false,
      valid: true,
      errored: false,
      errors: [],
    }
  },
  computed:{
    selectedTemplate(){
      return 'Dear '+(this.name ? this.name:'[USER]')+', <br><br> Congratulations once again on your wedding. It was a real pleasure being a part of it.<br><br>Would you be willing to write us a review on Mango & Lola? Online reviews and social media play a huge role in helping us find new couples to work with!<br><br>It should only take a couple minutes and would make an immense difference to our company.'
    }
  },
  methods: {
    SubmitFunction() {
      this.$http.post("api/vendor/request-review", {
        email: this.email,
        name: this.name,
        body: this.body,
      }).then((response) => {
            this.$toast.success(response.data.message, "Success");
            this.email = '';
            this.name = '';
            this.body = '';
            this.$emit('close');
            this.$emit('invitedForNewReview');
          })
          .catch((error) => {
            this.$toast.error(error.data.error, "Error");
          });
    },
    onSubmit(){
      if(this.validateForm()) this.SubmitFunction();
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

      if (!this.name) {
        label = "Name required.";
        this.errors.push(label);
        this.errors["name"] = label;
      }
      if (!this.body) {
        label = "Message body required.";
        this.errors.push(label);
        this.errors["body"] = label;
      }
      if (!this.email) {
        label = "Email Required";
        this.errors.push(label);
        this.errors["email"] = label;
      } else if (!this.validEmail(this.email)) {
        label = "Invalid Email";
        this.errors.push(label);
        this.errors["email"] = label;
      }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },    
    close() {
      this.$emit('close');
    }
  }
}


</script>
