<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div class="tw-text-center">
      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-3">{{ $t('dashboard.welcome') }}</h1>
      <h2 class="tw-block tw-text-black tw-text-md tw-mb-2 tw-leading-relaxed">Please complete the sections below with an empty Checkbox</h2>
      <div class="tw-items-center tw-block tw-text-black tw-text-md tw-mb-2 tw-leading-relaxed">
        <span>Your Account is Ready When All Checkboxes Are Marked</span>
        <check-square-icon class="tw-inline-flex tw-w-5 tw-h-5 tw-text-green-check tw-ml-2" stroke-width="2" />
      </div>
      <h2 class="tw-block tw-text-black tw-text-md tw-mb-2 tw-leading-relaxed">PLEASE NOTE: YOUR BUSINESS WILL NOT SHOW UP IN OUR FILTERS OR BE VISIBLE TO COUPLES UNTIL YOU HAVE LISTED AT LEAST 1 OFFER IN THE OFFER SECTION</h2>
     </div>
    <div class="tw-grid tw-grid-cols-3 tw-gap-8 tw-pb-10">
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendors.myCompany' })">
        <dashboard-card
        :title="$t('dashboard.menu.company')"
        :description="$t('dashboard.menu.company_label')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('company'))"
        :showCheckbox="true"
        >
          <settings-icon class="tw-w-5 tw-h-5"/>
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendors.showcaseWork' })">
        <dashboard-card
        :title="$t('dashboard.menu.showcase_work')"
        :description="$t('dashboard.menu.showcase_work_label')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('showcasework'))"
        :showCheckbox="true"
        >
          <eye-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendor.offers' })">
        <dashboard-card
        :title="$t('dashboard.menu.offerings')"
        :description="$t('dashboard.menu.offerings_label')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('offers'))"
        :showCheckbox="true"
        >
          <mail-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendors.calendar' })">
        <dashboard-card
        :title="$t('dashboard.menu.calendar')"
        :description="$t('dashboard.menu.calendar_label')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('availability'))"
        :showCheckbox="true"
        >
          <calendar-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendors.settings' })">
        <dashboard-card
        :title="$t('dashboard.menu.settings')"
        :description="$t('dashboard.menu.settings_label')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('settings'))"
        :showCheckbox="true"
        >
          <tool-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendor.payments' })">
        <dashboard-card
        :title="$t('dashboard.menu.payments')"
        :description="$t('dashboard.menu.payments_label')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('paymentplanstripe'))"
        :showCheckbox="true"
        >
          <dollar-sign-icon class="tw-w-5 tw-h-5"  />
        </dashboard-card>
      </div>
      <!--
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendor.terms' })">
        <dashboard-card
        :title="$t('dashboard.menu.couples_contract')"
        :description="$t('dashboard.menu.couples_contract_lebel')"
        :completeness="completedStepsIds.includes(getCompletenessStepsIds('terms'))"
        :showCheckbox="true"
        >
        <tag-icon class="tw-w-5 tw-h-5"  />
        </dashboard-card>
      </div>
       -->
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendor.quotes' })">
        <dashboard-card :title="$t('dashboard.menu.quote_requests')" :description="$t('dashboard.menu.quote_requests_label')">
          <arrow-down-right-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendor.bookings' })">
        <dashboard-card :title="$t('dashboard.menu.bookings')" :description="$t('dashboard.menu.bookings_label')">
          <arrow-down-right-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <div class="tw-col-span-1 tw-cursor-pointer" @click="$router.push({ name: 'vendors.ratings' })">
        <dashboard-card :title="$t('dashboard.menu.ratings')" :description="$t('dashboard.menu.ratings_label')">
          <star-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      <!--
      <div class="tw-col-span-1 tw-cursor-pointer" @click="inviteModal = true">
        <dashboard-card :title="$t('dashboard.menu.public_ratings')" :description="$t('dashboard.menu.public_ratings_label')">
          <star-icon class="tw-w-5 tw-h-5" />
        </dashboard-card>
      </div>
      -->

    </div>
    <v-dialog
        v-model="inviteModal"
        max-width="600"
    >
      <InviteToRate @close="inviteModal = false"/>
    </v-dialog>
    <div>

<!--      <v-tab href="#tab-mycompany">My Company</v-tab>
      <v-tab href="#tab-showcasework">Showcase work</v-tab>
      <v-tab href="#tab-product">Offerings</v-tab>
      <v-tab href="#tab-calendar">Calendar</v-tab>
      <v-tab href="#tab-quote">Quote requests</v-tab>
      <v-tab href="#tab-bookings">Bookings</v-tab>
      <v-tab href="#tab-ratingCollector">Ratings</v-tab>
      <v-tab href="#tab-message">Messages</v-tab>
      <v-tab href="#tab-settings">Settings</v-tab>
      <v-tab href="#tab-payment-method">Payment methods</v-tab>-->


<!--      <div v-if="tab === 'mycompany'">
        <MyCompany/>
      </div>
      <div v-if="tab === 'showcasework'">
        <ShowcaseWork/>
      </div>
      <div v-if="tab === 'calendar'">
        <Calendar/>
      </div>
      <div v-if="tab === 'offerings'">
        <Products/>
      </div>
      <div v-if="tab === 'quote'">
        <Quotes/>
      </div>
      <div v-if="tab === 'bookings'">
        <Bookings/>
      </div>
      <div v-if="tab === 'ratingCollector'">
        <RatingCollector/>
      </div>
      <div v-if="tab === 'message'">
        <Message/>
      </div>
      <div v-if="tab === 'settings'">
        <Settings/>
      </div>
      <div v-if="tab === 'paymentMethods'">
        <PaymentMethod/>
      </div>-->
    </div>



  </div>
</template>


<script>
import { mapGetters } from 'vuex'
/*import Calendar from './CalendarPage.vue'
import MyCompany from './MyCompanyPage.vue'
import PaymentMethod from './paymentMethodsPage.vue'
import Products from './products/ProductPage.vue'
import Quotes from './quotesPage.vue'
import Bookings from './bookings/bookingsPage.vue';
import Settings from './settingsPage.vue'
import ShowcaseWork from './showcasework/ShowcaseWorkPage.vue'
import RatingCollector from './ratings/ratingCollectorPage.vue'
import Message from '../MessagePage.vue'*/
import DashboardCard from "@/components/vendor/DashboardCard";
import { SettingsIcon, EyeIcon, MailIcon, CalendarIcon, ArrowDownRightIcon, StarIcon, DollarSignIcon, ToolIcon } from "vue-feather-icons";
import i18n from "@/i18n";
import { CheckSquareIcon } from "vue-feather-icons";
import InviteToRate from "@/components/InviteToRate";

export default {
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  components: {
    CheckSquareIcon,
    DashboardCard,
    SettingsIcon,
    EyeIcon,
    MailIcon,
    CalendarIcon,
    ArrowDownRightIcon,
    //TagIcon,
    StarIcon,
    DollarSignIcon,
    ToolIcon,
    InviteToRate,
    /*Calendar,
    MyCompany,
    Products,
    Quotes,
    Bookings,
    Settings,
    ShowcaseWork,
    RatingCollector,
    Message,
    PaymentMethod*/
  },
  computed: {
    ...mapGetters({
    user: 'auth/user',
    }),
    completedStepsIds: function() {
      return this.user.completeness_steps.map((item) => item.id);
    }
  },
  data() {
    return {
      loading: false,
      inviteModal: false,
      tab: 'offerings',
      steps: [],
    }
  },
  metaInfo() {
    return {
      title: i18n.t('general.my_dashboard'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendors/Dashboard'),
        },
      ]
    }
  },
  created() {
    this.getCompletenessSteps();
  },
  methods:{
    getCompletenessStepsIds(code){
      if(this.steps.length){
        return this.steps.filter(item => item.code == code)[0].id;
      }else{
        return null;
      }

    },
    getCompletenessSteps() {
      this.$http.get('api/completeness-steps?type=vendor')
        .then(({data}) => {
          this.steps = data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
  }
}
</script>
